var headroom, myElement;

Array.prototype.append = function(el) {
  if (this.push != null) {
    this.push(el);
  }
  return this;
};

myElement = document.querySelector("header");

headroom = new Headroom(myElement, {
  offset: 150,
  classes: {
    notTop: "header--not-top"
  }
});

headroom.init();

document.addEventListener('DOMContentLoaded', function() {
  var poduct;
  if ($('.slider-img').length > 0) {
    $('.slider-img').each(function(i, e) {
      var $this;
      $this = $(e);
      return $this.slick({
        dots: true,
        dotsClass: 'slider-dots',
        customPaging: function(slider, i) {
          if (slider.slideCount === 1 || slider.slideCount === slider.options.slidesToShow) {
            return false;
          }
          return "<a class='slider-dots-item'></a>";
        },
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<div class="slider-control slider-control-prev"></div>',
        nextArrow: '<div class="slider-control slider-control-next"></div>',
        speed: 500,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        useCSS: false,
        cssEase: 'linear'
      });
    });
  }
  poduct = document.getElementById('poductModal');
  return poduct.addEventListener('shown.bs.modal', function(event) {
    return $('.slider-img').slick('refresh');
  });
});
