Array.prototype.append = (el) ->
  this.push el if this.push?
  this

myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 150
  classes: 
    notTop: "header--not-top"
)
headroom.init()

document.addEventListener 'DOMContentLoaded', () ->


  if $('.slider-img').length > 0
    $('.slider-img').each (i, e) ->
      $this = $(e)

      $this.slick
        dots: true
        dotsClass: 'slider-dots'
        customPaging : (slider, i) -> 
          return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
          return "<a class='slider-dots-item'></a>"

        slidesToShow: 1
        slidesToScroll: 1
        prevArrow: '<div class="slider-control slider-control-prev"></div>'
        nextArrow: '<div class="slider-control slider-control-next"></div>'
        speed: 500
        fade: true
        autoplay: true
        autoplaySpeed: 5000
        useCSS: false
        cssEase: 'linear'

  poduct  = document.getElementById('poductModal')
  poduct.addEventListener('shown.bs.modal', (event) ->
    $('.slider-img').slick('refresh')
  )